/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 3, 2019 */



@font-face {
    font-family: 'proza_libreregular';
    src: url('../fonts/prozalibre-regular-webfont.eot');
    src: url('../fonts/prozalibre-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/prozalibre-regular-webfont.woff2') format('woff2'),
         url('../fonts/prozalibre-regular-webfont.woff') format('woff'),
         url('../fonts/prozalibre-regular-webfont.svg#proza_libreregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proza_librebold';
    src: url('../fonts/prozalibre-bold-webfont.eot');
    src: url('../fonts/prozalibre-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/prozalibre-bold-webfont.woff2') format('woff2'),
         url('../fonts/prozalibre-bold-webfont.woff') format('woff'),
         url('../fonts/prozalibre-bold-webfont.svg#proza_librebold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proza_libreextrabold';
    src: url('../fonts/prozalibre-extrabold-webfont.eot');
    src: url('../fonts/prozalibre-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/prozalibre-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/prozalibre-extrabold-webfont.woff') format('woff'),
         url('../fonts/prozalibre-extrabold-webfont.svg#proza_libreextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proza_libremedium';
    src: url('../fonts/prozalibre-medium-webfont.eot');
    src: url('../fonts/prozalibre-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/prozalibre-medium-webfont.woff2') format('woff2'),
         url('../fonts/prozalibre-medium-webfont.woff') format('woff'),
         url('../fonts/prozalibre-medium-webfont.svg#proza_libremedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proza_librelight';
    src: url('../fonts/prozalibre-light-webfont.eot');
    src: url('../fonts/prozalibre-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/prozalibre-light-webfont.woff2') format('woff2'),
         url('../fonts/prozalibre-light-webfont.woff') format('woff'),
         url('../fonts/prozalibre-light-webfont.svg#proza_librelight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proza_libreitalic';
    src: url('../fonts/prozalibre-italic-webfont.eot');
    src: url('../fonts/prozalibre-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/prozalibre-italic-webfont.woff2') format('woff2'),
         url('../fonts/prozalibre-italic-webfont.woff') format('woff'),
         url('../fonts/prozalibre-italic-webfont.svg#proza_libreitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proza_libresemibold';
    src: url('../fonts/prozalibre-semibold-webfont.eot');
    src: url('../fonts/prozalibre-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/prozalibre-semibold-webfont.woff2') format('woff2'),
         url('../fonts/prozalibre-semibold-webfont.woff') format('woff'),
         url('../fonts/prozalibre-semibold-webfont.svg#proza_libresemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}