#header{
	padding:0px 15px;	
	background:#fff;
	transition: all 0.3s;
	position:relative;
	z-index: 80;

	@include media(medium){
		padding:0px 40px;
	}
	.navbar{
		margin: 0;
		border:0;
	}


	.scroll &{
		box-shadow: 0 25px 30px 0 rgba(36, 50, 66, 0.08);
		position:fixed;
		width: 100%;
		z-index: 80;
		top:0;

	}
}

.navbar-brand{
	width: 165px;
	height: auto;
	padding:15px;
	background:#fff;
	position: relative;
    z-index: 80;
    margin-left: -15px !important;

    .gsc-overflow-hidden &{
    	z-index: 20;
    }

    svg{
    	width: 100%;
    }

	@include media(x-small){
		width: 263px;
		 margin-left: -30px !important;

	}


}

.navbar-toggle{
    margin: 0;
    padding: 0;
    width: 40px;
    height: 25px;
	border:0;
	border-radius: 0;
	z-index: 90;
    right: 0;
    top: 50%;
    position:absolute;
    display: block;
    transform: translateY(-50%);

    @include media(medium){
    	display: none;
    }

		>span{
			display: block;
		}

		.icon-bar{
		  background-color: #fff;
		  position: relative;
		  margin: 0 auto;
		  width: 40px;
		  height: 3px;
		  transform: none;
		  transition: all 0.3s ease-in-out;
			float: right;
			clear: both;

	      &+.icon-bar{
	          margin-top: 8px;
	      }
		}

		&:hover >.icon-bar:nth-child(1){
			// width: 15px;
		}

		&:hover >.icon-bar:nth-child(3){
			// width: 18px;
		}

		&.collapsed .icon-bar{
			background-color: #000;
		}
		&.collapsed .icon-bar:nth-of-type(1) {
		  top: 1px;
		  transform: none
		}
		&.collapsed .icon-bar:nth-of-type(2) {
		  background-color: #000;
		}
		&.collapsed .icon-bar:nth-of-type(3) {
		  top: -1px;
		  transform: none
		}

		.icon-bar:nth-of-type(1) {
		  top: 12px;
		  transform: rotate(45deg)
		}
	 	.icon-bar:nth-of-type(2) {
		  background-color: transparent;
		}
		.icon-bar:nth-of-type(3) {
		  top: -10px;
		  transform: rotate(-45deg)
		}
}

#primary-menu{

	font-size: 16px;
	padding:0;
	text-align:right;
	width: 90%;
	position:absolute;
	z-index:80;
	right: -15px;
	top: 0;
	bottom: 0;
	background:$primary-color;
	height: 100vh !important;
	padding-top: 100px;
	font-family: 'proza_librebold';

	@include media(x-small){
		width: 50%;	

	}

	@include media(small){
		width: 40%;
	}

	@include media(medium){
		background:0;
		padding-top: 0;
		width: auto;
		font-family: 'proza_libreregular';
		height: auto !important;
		z-index: 70;	
	}
	

	.top-icons{
		
		vertical-align: middle;
		padding:46px 12px 0;
		display: none;
		position:relative;

		@include media(medium){
			display: inline-block;
		}
	}

	.search-form{
	  position: absolute;
	  right: -4px;
    	top: 37px;
	  width: 237px;
	  opacity: 0;
	  visibility: hidden;


	  .btn-close{
	    position: absolute;
	    right: 53px;
	    top: 0;
	    width: 30px;
	    height: 30px;
	    display: block;
	    border-radius: 50%;
	    cursor: pointer;
	    opacity: 0.5;
	    transition: all 0.3s;

	    &:hover{
	      opacity: 1;
	    }

	    &::before, &::after{
	      width: 2px;
	      height: 15px;
	      content: "";
	      display: block;
	      position: absolute;
	      background: #000;
	      top:50%;
	      transform: translateY(-50%) rotate(-45deg);
	      margin-left: 11px;
	    }

	    &::before{
	        transform: translateY(-50%) rotate(-45deg);
	    }
	    &::after{
	      transform: translateY(-50%) rotate(45deg);
	    }
	  }

	  &.active{

	    opacity: 1;
	    visibility: visible;

	    input{
	      opacity: 1;
	      visibility: visible;
	      transform: translateX(0);
	    }

	    button[type="submit"]{
	      opacity: 1;
	      visibility: visible;
	    }

	  }

	  input{
	    border: 0;
	    border-radius: 20px;
	    background: #e5e5e5;
	    padding: 4px 36px 4px 15px;
	    font-weight: 300;
	    outline: 0;
	    font-size: 16px;
	    width: 220px;


	    overflow: hidden;
	    transition: all 0.3s;
	    opacity: 0;
	    visibility: hidden;
	    transform: translateX(20px);
	  }
	  button[type="submit"]{
	    border: 0;
	    background: 0;
	    position: absolute;
	    right: 10px;
	    top: 4px;
	    outline: 0;
	    opacity: 0;
	    visibility: hidden;


	    &.disabled{
	      pointer-events: none;
	    }
	  }
	}

	&.navbar-collapse.collapse{
		display: none !important;

		@include media(medium){
			display: block !important;
		}


		&.in{
			display: block !important;


			.main-menu > li{
				visibility: visible;
			    opacity: 1;
			    transform: translateY(0);
			}
			.main-menu > li:nth-child(1){transition-delay: 0.1s;}
			.main-menu > li:nth-child(2){transition-delay: 0.2s;}
			.main-menu > li:nth-child(3){transition-delay: 0.3s;}
			.main-menu > li:nth-child(4){transition-delay: 0.4s;}
			.main-menu > li:nth-child(5){transition-delay: 0.5s;}
			.main-menu > li:nth-child(6){transition-delay: 0.6s;}
			.main-menu > li:nth-child(7){transition-delay: 0.7s;}
			.main-menu > li:nth-child(8){transition-delay: 0.8s;}
			.main-menu > li:nth-child(9){transition-delay: 0.9s;}
			.main-menu > li:nth-child(10){transition-delay: 1s;}
		}
		
	}
}

.main-menu{
	float: none;
	display: inline-block;
	margin-right: 35px;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	padding-bottom:40px;
	transition: all 0.3s;
	z-index: 70;
	position:relative;

	.search-on &{
		transform: translateX(-195px);
	}
	

	@include media(medium){	
		height: auto;
		padding:0;
		margin-right: 0;
		overflow: visible;
		vertical-align: middle;
	}



	li{



		
		&:last-child{
		
		}

		a{	
			
			color: #3c3c3c;
			padding:0 20px;
			line-height: 18px;
		
			background: 0;
			position:relative;

			@include media(medium){
				text-align:center;
			}


			&:focus, &:hover{
				background:0;
			}


		}


	}

	>li{	
		
		float: none;
		padding-right:30px;

	    transition: all 0.3s;
	    transform: translateX(-50px);
	    opacity: 0;
	    visibility: hidden;

		@include media(medium){
			float: left;
			padding-right:inherit;
			height: 117px;
			transform: none;
			opacity: 1;
			visibility: visible;
		}

		>a{		


			padding:12px 0px;				
			transition: all 0.3s;

			@include media(medium){
				padding:58px 14px 19px;
			
			}


			@include media(medium-large){
				padding:58px 20px 19px;
				min-width: 127px;	
			}

			span{
			


				@include media(medium){
					display: table;
					height: 40px;
					width: 100%;
				}

				span{
					

					@include media(medium){
						display: table-cell;
						position:relative;
						vertical-align: middle;
					}
					
					&::after{
						

						@include media(medium){
							content: "";
							position:absolute;
							right: -16px;
							height: 36px;
							width: 1px;
							top:50%;
							transform: translateY(-50%);
							background:#d0d0cd;
						}

						@include media(large){
							right: -21px;
						}



					}	
				}
			}


			&::after{						
				

				@include media(medium){
					/*width: 100%;
					height: 30px;
					content: "";
					position:absolute;
					top:100%;
					display: block;
					opacity: 0;
					left: 0;
					transform: translate(30px,-5px);
					z-index: 80;
					transition: all 0.3s;
					background:url('../images/arrow-down.svg') no-repeat center bottom;
					background-size: contain;*/

					content: "";
					position:absolute;
					top:99%;
					left: 50%;
					transform: translate(-50%,-5px);
					opacity: 0;
					z-index: 80;
					transition: all 0.3s;

					width: 0;
					height: 0;
					border-style: solid;
					border-width: 20px 40px 0 40px;
					border-color: #bfbb93 transparent transparent transparent;
					line-height: 0px;
					_border-color: #bfbb93 #000000 #000000 #000000;
					_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
				}

				@include media(medium-large){
					border-width: 20px 63px 0 63px;

				}

			}


			&.dropdown-toggle{

				&::before{
					content: "";
				    position: absolute;
				    right: -27px;
    				top: 18px;
				    color: #fff;
				    font-size: 20px;
				    line-height: 20px;
				    display: block;
				    width: 14px;
				    height: 8px;
				    background: url('../images/arrow-up.svg') no-repeat 0 0;
				    transition: all 0.3s;
				    transform: rotate(-180deg);

					@include media(medium){
						display: none;
					}
				}
			}

			br{
				display: none;
				@include media(medium){
					display: block;
				}
			}

		}

		&.open,
		
		&:hover{

			>a{
				background:$primary-color !important;
				color: #fff;

				span{

					span{
						&::after{
							opacity: 0;
						}
					}
				}

				&::after{
					transform: translate(-50%,0px);
					opacity: 1;
				}
			}
		}

		&.open{

			>.dropdown-toggle{

				&::before{
					transform: rotate(0);
				}
			}
		}


		&.active{
			>a{
				color: #fff;

				@include media(medium){
					color: $primary-color;
				}
			}

			&:hover >a{
				color: #fff;
			}
		}
		
	}
}


.dropdown-menu{
	font-family: 'proza_libreregular';
	position: static;
	background: transparent;
	box-shadow: none;
	border: 0;
	float: none;
	font-size: 14px;
	border-right: 1px solid #fff !important;
	border-radius: 0;
	margin-right: 0;
	margin-bottom: 0;
	margin-top: 30px;
	padding: 0;
	text-align: right;
	min-width: 240px;

		.dropdown-menu{
			left: 100%;
			top:0;
			margin-top: 0;


			@include media(max-small){



			}

			li{
				@include media(max-small){
					
				}

				a{

					

				}
			}
		}

	@include media(medium){
		z-index: 70;
		background:#fff;
		text-align: left;
		border:1px solid rgba(0, 0, 0, 0.15) !important;
		padding:35px 27px 30px;
		border-radius:0;
		position:absolute;
	}

	.open >&{

	}


	li{
		text-align:left;
		padding-right:15px;

		@include media(medium){
			padding-right:0;
		}

		a{
			
			font-size: 14px;	
			padding:7px 0;
			text-align: right;

			@include media(medium){
				text-align: left;
				font-size: 16px;
			}
			
			&:hover{
				background:0;
				color: $primary-color;
			}

		}


		&.active{
			
			>a, >a:hover{
				color: $primary-color;
				background:0;
			}
		}


		&.group{
			
			

			~li{

				a{
					padding-left: 10px;
				}
			}

		}
	}
}
.navbar-header{
	float:none;
	position:relative;
	@include media(medium){
		float: left;
	}

}



.mobile-search-form{
	position:relative;
	margin-top: 20px;
	.q{
		background:0;
		border:1px solid #fff;
		border-radius:20px;
		font-family: 'proza_libreregular';
		padding:5px 40px 5px 10px;
		outline: 0;
		background: #cac6a0;
    	font-size: 14px;

	}
	[type="submit"]{
		border:0;
		background:0;
		position:absolute;
		right: 0;
		top:6px;
		outline: 0;
		cursor: pointer;

		path{

			fill:#fff !important;
		}
	}
	
	@include media(medium){
		display: none;
	}	
}