body {
	font-size: 16px;
	font-family: 'proza_libreregular';
	color: #191919;
	position: relative;


	&.scroll{
		padding-top:115px;
	}

	@include media(small){
		font-size: 18px;
	}



}

.full-height{
	height: 100%;

	body{
		height: 100%;
	}
}
b, strong{
	font-family: 'proza_libresemibold';
	font-weight: normal;
}

.label{
	    font-weight: normal;
	    padding: 5px 10px;
}

.h1, .h2, .h3,
h1, h2, h3 {
	font-family: 'proza_libresemibold';
	margin: 0 0 2rem;
	font-weight: 700;
	color: #444444;
}
h4, h5,
.h4, .h5 {
	margin: 0 0 1rem;
}
h1,.h1 {
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 47px;

	@include media(medium) {
		font-size:47px;
	}
}

h2,.h2 {
	font-size: 24px;
	line-height: 1;

	@include media(small) {
		font-size: 28px;
	}

}
h3,.h3 { 
	font-size: 24px;
	font-weight: 300;
	line-height: 1.2;

}
h4,.h4 {
	font-size: 18px;
	font-weight: bold;
	@include media(medium) {
		font-size: 1.125em;
	}
}

a {
	color: #000;
	&:hover, &:focus {
		color: #bfbb93;
		text-decoration: none;
	}
}

p{
	margin-bottom: 30px;
}

.container {
	@include media(medium) {
		width: 1260px;
	}
}




.overflow-hidden{
	overflow: hidden;
}

@keyframes imageZoom {

  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}


.highlight{
	background:beige;
	display: inline-block;
	padding:3px 5px;
}

.btn-group-lg > .btn, .btn-lg{
	font-size: 14px;


	@include media(medium){
		font-size: 18px;
	}
}

.no-link{
	pointer-events:none !important;
}