@import 'partials/fonts';
@import 'partials/animate';
@import 'partials/bootstrap';
@import 'partials/flexslider';
@import 'partials/fontawesome';
@import 'partials/niceselect';
@import 'partials/fancybox';

/*------------------------------STARTS HERE--------------------------------*/
@import 'partials/mixins';
@import 'partials/variables';
@import 'partials/pseudo';
@import 'partials/header';
@import 'partials/banner';
@import 'partials/content';
@import 'partials/modules';
@import 'partials/footer';
