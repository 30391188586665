#header,
#banner,
.mod-figures,
.section, 
#footer{
	max-width: 1600px;
	margin-left: auto !important;
	margin-right: auto !important;

}
.scroll #header{
	left: 0;
    right: 0;
}

.section{
	padding: 30px 0px 10px;

	@include media(small){
      padding: 50px 25px;
    }

  
	h2{
		margin-top: 0;
		margin-bottom: 30px;
		line-height: 1.5;
	}
}

.lead{
	font-size: 20px;
	font-family: 'proza_libremedium';
	color:#525252;
	position:relative;
	text-align: left;


	@include media(small){
		font-size: 30px;
	}

	&.quote{
		padding-top:48px;

		&::before{
			content: '“';
			font-family: 'proza_libremedium';
			position:absolute;
			left: 0;
			top: 0;
			font-size: 77px;
			line-height: 77px;
		}
	}
}
.main-content{
	position:relative;
	padding:20px;

	h1, h2,h3, h4,
	.h1, .h2 ,.h3, .h4
	{
		color: #bfbb93;
		padding-top:13px;
	}
	.title{
		position:relative;
		color: #bfbb93;
		padding-top:13px;
		text-align: left;

		&::before{
			content: "";
			width: 93px;
			height: 4px;
			left: 0;
			top:0;
			position:absolute;
			background:$primary-color;
		}
	}


}
#main{
	width: 100%;	
	position:relative;

	a{
		color: $primary-color;
	}

	@include media(medium){
		width: 70%;
		text-align: justify;
	}

	&.full{
		width: 100%;

	}
	

	.full-height &{
		height: 100%;

		.body{
			padding:20px;
			max-width: 1200px;
			margin: auto;
		}
	}
}	

.content{

	a{
		color: $primary-color;
	}
}

.disp-table{
	display: table;
	width: 100%;
	height: 100%;
}
.disp-cell{
	display: table-cell;
	vertical-align: middle;
}
#sidebar{
	position: absolute;
	right: 20px;
	top:0;	
	width: 100%;

	

	@include media(small){
		width: 380px;
		padding-top:150px;
		padding-left:50px;
	}


	@include media(medium){
			
		text-align: right;
		
	}

	@include media(large){
			
		padding-left: 0;
		
	}

	@include media(max-small){
		position:static !important;
	}


	.title{
		padding-bottom:18px;
		color: #525252;
		margin-bottom: 16px;
		text-align: right;

		&::before{
			display: none;
		}
	}

	ul.nav{
		padding:20px 0;
		margin:0;
		list-style: none;
		position:relative;


		&::before,
		&::after{
			position:absolute;
			content: "";
			width: 100%;
			height: 4px;
			left: 0;

			@include media(medium){
				right: 0;
				left: auto;
			}
		}

		&::before{
			top:0;
			
			background:#bfbb93;

			@include media(medium){
				width: 80%;
			}

			
		}
		&::after{
			border:0;
			background:#92838d;
			bottom:0;

			@include media(medium){
				
			}
		}

		li{
			padding:3px 0;

			a{
				color: #696767;
				padding: 2px 0;
    			background: transparent !important;

				&:hover{
					color: $primary-color;
				}
			}

		&.active >a{ color: $primary-color; }

		}

		ul{
			list-style: none;
			font-size: 15px;
			margin-right:2px;
			border-left:2px solid $primary-color;
			padding: 10px 0px 2px 15px;

			@include media(small){
				padding: 10px 15px 2px 0;
				border-right:2px solid $primary-color;
				border-left:0;
			}


			> li{

				position:relative;

				&::after{
					position:absolute;
					content: "";
					width: 10px;
					height: 2px;
					top:50%;
					left: -15px;
					transform: translateY(-50%);
					background-color:$primary-color;

					@include media(small){
						right: -15px;
						left: auto;

					}
				}
			}
		
		}
	}
}



@media (max-width: 1200px){

	#sidebar,
	.scrollmagic-pin-spacer{	
		position: static !important;
		padding:0 !important;
		margin:0 !important;
		width: 100% !important;			

	}


}

.table-note{
	margin: 15px;
}


.table-responsive{
	
	@include media(max-small){
		margin-bottom: 30px;
	}
}


.footnote{
	font-size: 12px;

	>tbody{
		>tr{
			margin-bottom: 10px;
		}
	}

	td{
		vertical-align: top;
		padding:2px 2px 7px;
	}
}




.state{


	&.tx{top: 70%;left: 41%;}
	&.ga{left: 74%;top: 64%;}
	&.fl{left: 79.5%;top: 76%;}

	&.nj{left: 85%;    top: 36%;}
	&.ct{left: 89%;    top: 30%;}
	&.ny{    left: 84%;    top: 27%;}
	

  .county{  
  	 &.dallas{left: 50px; top: 35px;}
  	 &.houston{left: 112px; top: 82px;}

  	 &.atlanta{left: 40px; top: 45px;}
  	 &.orlando{left: 35px; top: 80px;}


  	 &.woodbridge{left: 45px; top: 40px;}
  	 &.danbury{left: 45px; top: -40px;}


  	&.Sacramento{ top:60px; left: -20px; }
    &.San-Mateo{ top:100px; left: 75px; width: 100px; }
    &.San-Jose{ top:140px; left: 30px; width: 100px; }
    &.Pasadena{ top:125px; left: 150px; width: 100px; }
    &.Palm-Desert{ top:175px; left: 200px; width: 100px; }

    &.Salt-Lake{ top:110px; left: 240px; width: 150px; }



    &.Long-Beach{ top:186px; left: 35px; width: 100px; }
    &.Anaheim{ top:230px; left: 130px; }
  
  

  }

}