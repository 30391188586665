#banner{
	height: 400px;
	overflow: hidden;
	padding:0;


	.page &{
		height: 275px;

		@include media(small){
			height: 600px;
		}
	}

	@include media(x-small){
		height: 400px;
	}

	@include media(small){
		height: 600px;
	}

	.flexslider, .flex-viewport, .slides{
		height: 100%;
	}

	.flexslider{

	}
	.slides{	

		li{	
			height: 100%;
			background-size:cover;
			background-repeat: no-repeat;
			background-position: center bottom;

			@include media(max-small){
				background-position: center !important;
			}

			&.w-inside-banner{
				font-size: 0;
			}

			.caption{
				position:absolute;
				bottom:25px;
				right: 0;
				color: #fff;
				text-shadow: 1px 1px 1px #000;
				font-family: 'proza_libreitalic';
				font-size: 15px;
				padding:0 10px;
				text-shadow: 2px 0px 24px #000, 2px 0px 24px #000;


				@include media(small){
					padding:0 20px;
					right: 45px;

				}
			}

			.inside-banner{
				font-size: 0;
				display: inline-block;
				background-size:cover;
				background-repeat:no-repeat;
				background-position:center;
				width: calc( 100% /3 );
				height: 100%;
				position:relative;
			}
		}
	}

	.flex-control-nav{
		visibility: hidden;
		opacity: 0;
	}

	.tab-heading{
		position: absolute;
		z-index: 20;
		bottom:0;
		width: 100%;		
		color: #fff;
		height: 50px;


		@include media(x-small){
			height: 53px;
		}

		@include media(medium){
			height: 62px;
		}

		@include media(large){
			height: 68px;
		}

		a{
			color: #fff;
			display: block;
		}


		

		ul{
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			display: block;
			font-size: 0;
			height: 100%;

			li{
				background: #85a692;
				padding:14px;
				display: inline-block;
				vertical-align: middle;
				transition: all 0.3s;
				width: 30%;
				font-size: 16px;
				position:relative;

				@include media(x-small){
					width: 20%;
				}

				&::before{
					content: "";
					position:absolute;
					bottom: 100%;
					right: 10px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 30px 15px 30px;
					border-color: transparent transparent #85a692 transparent;
					visibility: hidden;
					opacity: 0;
					transition: all 0.3s;


					@include media(x-small){
						left: 150px;
						right: auto;
						border-width: 0 50px 20px 50px;
					}
					
				}

				

				&:nth-child(1){ background: #6f8fae; &::before{border-color: transparent transparent #6f8fae transparent;} }
				&:nth-child(2){ background: #ce946d;  &::before{border-color: transparent transparent #ce946d transparent;}}


				&.active{
					width: 40%;

					@include media(x-small){
						width: 60%;
					}

					&::before{
						opacity: 1;
						visibility: visible;

					}

					.caption{
						opacity: 1;
						visibility: visible;
					}
				}


			}
		}

		.category{
			font-size: 13px;
			font-family: 'proza_librebold';
			text-transform: uppercase;
			display: inline-block;
			vertical-align: middle;
			position:relative;

			@include media(x-small){
				font-size: 18px
			}

			@include media(small){
				font-size: 20px
			}

			@include media(medium){
				font-size: 28px
			}

		}

		.bar{
			width: 30px;
			height: 3px;
			background:#fff;
			display: inline-block;
			vertical-align: middle;
			margin: 0 15px;
		}
		.caption{
			vertical-align: middle;
			font-family: 'proza_libreregular';
			text-transform: none;
			font-size: 16px;
			position:absolute;
			left: 100%;
			display: block;
			width: 1000px;
    		top: 0;
    		opacity: 0;
    		visibility: hidden;

			@include media(small){
				font-size: 16px
			}

			@include media(medium){
				font-size: 22px
			}


			@include media(small){
				padding:5px 0;
			}

			span{
				

				@include media(max-small){
					display: none;
				}
			}
		}
		span.caption-text{

			width: 20%;

			@include media(max-small){
				display: none;
			}

		}

	}
}