#footer{
  font-size: 12px;
  text-align: center;
  padding: 30px 0;
  color: #616161;
  line-height: 2;


   @include media(small){
      padding: 52px 0;
      font-size: 14px;
    }

  .bar{
    display: inline-block;
    vertical-align:middle;
    padding:0 4px;


     @include media(small){
       padding:0 10px;
    }


  }

  .footer-links{
    display: block;

    @include media(small){
      display: inline;
    }
  }

  p.copy{
    margin-bottom: 0;
  }

  .bar-first{

    @include media(max-small){
      display: none; 
    
    }
      
  }



  
}

#fl-disclaimer{
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
}