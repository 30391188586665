.mod-figures{
  background-repeat: no-repeat;
  background-size:cover;
  padding:50px 0;
  background-position: center top;
  color: #fff;
  overflow: hidden;
  margin: 0px;

  @include media(small){
      padding: 97px 0;
  }

  .figure{
    background:#bfbb93;
    padding:20px 38px;
    font-size: 25px;
    margin: 15px 15px;
    

    @include media(small){
      padding:50px 38px;
      float: left;
      margin: 0;
      width: calc(100% / 3);
    }

    span{
      display: inline-block;
      vertical-align: middle;
    }

    &:nth-child(2){background:#615d85;}
    &:nth-child(3){background:#a3738a;}


  }
  .number{
    font-size: 27px;
    font-family: 'proza_librebold';
    padding-right: 20px;
    position:relative;

    @include media(small){
      font-size: 33px;
    }

    @include media(large){
      font-size: 80px;
    }

    sub{
      font-size: 16px;

      @include media(medium){
        font-size: 20px;
      }
    }

    span:not(.counter){
      font-size: 14px;
      position: absolute;
      left: 100%;
      line-height: 22px;
      top:50%;
      font-family: 'proza_libreregular';
      transform: translateY(-50%);
       min-width: 178px;
      
      @include media(small){
         min-width: 250px;
         font-size: 18px;
      }

       @include media(medium){
        font-size: 25px;
        line-height: 30px;
      }


    }
  }

  &::after{
    clear: both;
    display: block;
    content: "";
  }
}

.mod-blog-list{
  list-style: none;
  padding:0;
  margin: 0 0 40px;


  .full &{

    margin-right: -15px;
    margin-left: -15px;

    li{
      width: 100% !important;
    }

  }

  .entry{
    font-size: 18px;
    margin-bottom: 20px;    
    font-family: 'proza_libremedium';

    @include media(medium){
      font-size: 20px;
    }

    &:after{
      display: block;
      clear: both;
      content: "";
    }

    >a{
      color: #444444;
      clear: both;
      display: table;
    }

    .date, .summary{
      display: table-cell;
      vertical-align: top;
    }
    .date{
      width: 60px;
      border-top: 4px solid #bfbb93;
      font-size: 18px;
      font-family: 'proza_libreextrabold';
      margin-right: 25px;
      padding:4px;
    }
    .summary{
      padding-left:25px;
    }
  }

  + .mod-btn{margin-left: 84px;}
}
.btn{
  font-family: 'proza_librebold';
  text-transform: uppercase;
  color: #fff;
  background:#bfbb93;
  border-radius:0;
  letter-spacing: 2px;
  padding:8px 16px;
  transition: all 0.3s;

  &:hover{
    color: #fff;
    background:#afab86;
  }
}

.mod-catalog{
  list-style: none;
  padding:0;
  margin:0 -22px;

  &::after{
    clear: both;
    content: "";
    display: block;
  }

  li{
    margin:0 22px 20px;
    width: 144px;
    float: left;




    img{
      width: 100%;
      -webkit-box-shadow:0px 0px 7px #dcdcdc;
      -moz-box-shadow:0px 0px 7px #dcdcdc;
      box-shadow:0px 0px 7px #dcdcdc;
      transition: all 0.5s;

      &:hover{
       
      }

    }
    span{
      font-family: 'proza_librebold';
      font-size: 13px;
      color: #fff;
      padding:10px;
      display: block;
      background:#6a7b9d;
    }

    &:nth-child(2){

      span{
        background:#886a88;
      }
    }
  }

}

.mod-stocks{

  .bar{
    font-family: 'proza_libreextrabold';    
    display: inline-block;
    position:relative;
    margin-bottom: 26px;

    &::after{
      content: "";
      height: 5px;
      width: 100%;
      position: absolute;
      background:#bfbb93;
      bottom: 5px;
      left: 0;
    }
  }
  .currency{
    font-size: 16px;
    color: #929292;

     @include media(medium){
      font-size: 26px;
    }
  }
  .figure{
    color: #bfbb93;
    font-size: 37px;

     @include media(medium){
      font-size: 47px;
    }

  }

  .note{
    font-size: 11px;
    color: #474747;
  }
}

.nav-tabs{
  border-bottom:1px solid $primary-color;
  >li{


    >a{
      border:0 !important;
      border-radius:0 !important;
      margin: 0 !important;
      transition: all 0.3s;



      &:focus{
         color: #fff;
          background: $primary-color;
      }
    }

    &:hover,
    &.active{

      a, a:focus{
        color: #fff  !important;
        background: $primary-color !important;
      }
    }

  }
}
.tab-content{
  padding:20px 0;
}
.panel-default {
  border:0;

  > .panel-heading{    
      background-color: $primary-color;
      
      .panel-title{
        color: #fff !important;
        padding:0 !important;
        font-family: 'proza_libremedium';
        font-weight: normal;
      }
  }
}
.panel-group{

  .panel{
    border-radius:0;
  }
  .panel-heading{
    border-radius:0;

    a{

      &:hover, &:focus{
        color: #fff;
      }
    }
  }

}
.table{
  margin-bottom: 100px;
  border-bottom:1px solid #bfbb93;
}
.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th{
  border-top-color:$primary-color;
}
.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th{
      border: 1px solid $primary-color;
}

hr{
  border-top: 1px solid $primary-color;
}

.tab-menu{
 

  @include media(x-small){
     float: right;
    position:relative;
    z-index: 70;
  }

  ul{
    border:1px solid $primary-color;
    padding:0;
    margin:0;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    list-style: none;
        display: inline-block;
        
    li{
      display: inline-block;
      border-right:1px solid $primary-color;

      &:nth-child(2){

        a{


          &::after{

          }
        }


        &.active:nth-child(2){

          a{
            background-color:#6f8fae !important;
            &::after{

                 border-color: #6f8fae transparent transparent transparent;
            }
          }
        }
      }


      &:nth-child(3){

        a{


          &::after{

          }
        }


        &.active:nth-child(3){

          a{
            background-color:#ce946d !important;
            &::after{

                 border-color: #ce946d transparent transparent transparent;
            }
          }
        }
      }



      &:nth-child(4){

        a{


          &::after{

          }
        }


        &.active:nth-child(4){

          a{
            background-color:#85a692 !important;
            &::after{

                 border-color: #85a692 transparent transparent transparent;
            }
          }
        }
      }

      &:last-child{
        border-right:0;
      }

      a{
        color: #525252;
        font-family: 'proza_libremedium';
        padding:15px;
        display: block;
        position:relative;

        @include media(small){
         padding:15px 30px
        }

        @include media(max-small){
         padding:10px 15px;
         font-size: 12px;
        }

        &::after{

            transition: all 0.3s;
            content: "";
            top: 100%;
            position:absolute;
            width: 0;
            height: 0;
            left: 50%;
            transform: translateX(-50%);
            border-style: solid;
            border-width: 15px 50px 0 50px;
            border-color: transparent transparent transparent transparent;

          }
      }

      &.active{

        a{
          
          &::after{
            border-color: #bfbb93 transparent transparent transparent;


          @include media(max-small){ display: none; }

          }
        }
      }

    }
  }
}



.map-legend{
  text-align: center;
  position:relative;
  font-size: 0;
  margin-bottom: 30px;

  &::before{
    content: "";
    width: 100%;
    height: 8px;
    background:#bfbb93;
    top:50%;
    left: 0;
    position:absolute;
    transform: translateY(-50%);
    z-index: 10;
  }

  .legend{
    display: inline-block;
    vertical-align: middle;
    padding:0 5px 0 5px;
    position:relative;
    z-index: 15;
    font-size: 13px;
    font-family: 'proza_libresemibold';
    background: #fff;



    @include media(x-small){
      font-size: 16px;
      padding:0 20px 0 50px;
    }
    @include media(small){
      
    }
    @include media(medium){
      padding:0 20px 0 100px;
    }

    .marker{
      display: inline-block;
      vertical-align: middle;
      margin-right:4px;
      width: 24px;
      height: 28px;

       @include media(x-small){
         margin-right:8px;
      }

      &::after{
        width: 8px;
        height: 8px;
      }
      
    }
    &:first-child{
      padding-left:5px;

      @include media(x-small){
         padding-left:20px;
      }
    }

    &:last-child{
      padding-right:5px;

      @include media(x-small){
         padding-right:20px;
      }
    }
  }
}

#svg-map{
  background:#fdfcf6;

  @include media(max-small){
    overflow: hidden;
  }

  .map-holder{
    width: 340px;
    margin: 0 auto;
    position:relative;

    @include media(x-small){
      width: 700px;
    }


    @include media(small){
      width: 900px;
    }

    @include media(medium){
      width: 1109px;
    }

    svg{
      width: 100%;
      position:relative;
      z-index: 10;
    }
  }
}


#map{

  path{
    transition: all 0.3s;

    &:hover{
      fill:#949494;
    }


    
    &#CA:hover,&#CA.on{ fill: #bfbb93; }
    &#UT:hover,&#UT.on{ fill: #bfbb93; }
    &#CO:hover,&#CO.on{ fill: #bfbb93; }
    &#TX:hover,&#TX.on{ fill: #bfbb93; }
    &#GA:hover,&#GA.on{ fill: #bfbb93; }
    &#FL:hover,&#FL.on{ fill: #bfbb93; }
    &#NY:hover,&#NY.on{ fill: #bfbb93; }
    &#NJ:hover,&#NJ.on{ fill: #bfbb93; }
    &#CT:hover,&#CT.on{ fill: #bfbb93; }

    
  }


  .st0{fill:#C7C8CA;}
  .st1{fill:none;stroke:#FFFFFF;stroke-width:0.308;stroke-miterlimit:10;}
  .st2{fill:#E6E7E8;}
  .st3{fill:none;stroke:#FFFFFF;stroke-width:0.308;}
  .st4{fill:none;}

}
.marker{
  width: 42px;
  height: 48px;
  position:relative;

  &::after{
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    position:absolute;
    left: 50%;
    top:50%;
    z-index: 20;
    transform: translate(-50%,-50%);
  }
  svg{
    width: 100%;

    &.blue path{fill:#7090ae;}
    &.orange path{fill:#ce946d;}
    &.green path{fill:#86a692;}
  }


}

.state{
  position:absolute;
  font-family: 'proza_libresemibold';
  font-size: 10px;
  z-index: 30;


  &.active{
    z-index: 40;
  }
  >span{ pointer-events: none;}


  @include media(x-small){
    font-size: 18px;
  }

  @include media(medium){
    font-size: 24px;
  }

  &.ca{top: 36%;left: 5%;}
  &.co{top: 48%;left: 30%;}
  &.ut{top: 46%;left: 22%;}


  .county{  

    &.denver{left: 88px; top: 15px;}

   
  }



}

.map-data{
  &.Holiday-Inn-East-Denver{    left: 35%;    top: 42%;}
  &.Renaissance-Denver-Stapleton{    left: 32%;    top: 48%;}
  &.Sheraton-Denver-Tech-Centre{    left: 37%;    top: 49%;}



  &.Doubletree-by-Hilton-Salt-Lake-City-Airport{    left: 20%;    top: 38%;}





  &.Crowne-Plaza-Dallas-Near-Galleria-Addison{    left: 43%;    top: 66%;}
  &.Hilton-Houston-Galleria-Area{    left: 51.5%;    top: 74%;}


  &.Hilton-Atlanta-Northeast{    left:76.5%;    top: 62%;}
  &.Holiday-Inn-Resort-Orlando-Suites-Waterpark{    left: 80.5%;    top: 79%;}

  &.Crowne-Plaza-Danbury{    left: 90%; top: 25%;}
  &.Renaissance-Woodbridge{    left: 86.5%; top: 34.5%;}


  &.The-Westin-Sacramento{    left: 5%;    top: 40%;}
  &.Holiday-Inn-Hotel-Suites-San-Mateo{    left: 3%;    top: 46%;}
  &.Four-Points-by-Sheraton-San-Jose-Airport{       left: 7%;    top: 48%;}

  &.Sheraton-Pasadena{ left: 11%;    top: 48%;}
  &.The-Queen-Mary-Long-Beach{ left: 6%;    top: 54%;}
  &.Embassy-Suites-By-Hilton-Palm-Desert{    left: 14%;    top: 54%;}
  &.Holiday-Inn-Hotel-Suites-Anaheim{       left: 10%;    top: 54%;}
  &.Embassy-Suites-By-Hilton-Anaheim-North{        left: 10%;    top: 60%;}






}



.county{
  position:absolute;
  top:100%;
  z-index: 20;
  color: #000;
  font-size: 15px;
  font-family: 'proza_libresemibold';
  left: 50%;
  transform: translateX(-50%);
  pointer-events:none;

}

.map-data{
  position:absolute;
  padding:10px;  

  .marker{
    cursor: pointer;
  }

  &:hover .infobox{

    visibility: visible;
    opacity: 1;
    transform:translateX(0);
  }
}


.infobox{
  position:absolute;
  left: 60px;
  bottom: -40px;
  width: 389px;
  background:#7090ae;
  border-radius:18px;
  color: #fff;
  z-index: 30;
  padding:20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  transform:translateX(-30px);
  box-shadow: 0px 0px 22px #717171;


  .green + &{
    background:#86a692;

    &::after{
     border-color: transparent #86a692 transparent transparent;
    }

    .box-header .marker  svg path{
      fill: #567562;
    }

    .box-body tr td:first-child{
      color: #e7fff0;
    }

    .box-body .table,
    .box-body tr td{
      border-color: #658470;
    }
  }


  .orange + &{
    background:#cd946d;

     &::after{
     border-color: transparent #cd946d transparent transparent;
    }

    .box-header .marker  svg path{
      fill: #a07151;
    }

    .box-body tr td:first-child{
      color: #ffe9da;
    }

    .box-body .table,
    .box-body tr td{
      border-color: #a27758;
    }
  }







  &::after{
    content: "";

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #7090ae transparent transparent;

    position:absolute;
    right: 100%;
    bottom:50px;
  }

  .box-header{
    font-size: 22px;
    font-family: 'proza_libresemibold';
    line-height: 20px;
    margin-bottom: 20px;
    position:relative;
    padding-left:28px;

    .marker{
      position:absolute;
      left: 0;
      z-index: -1;
      bottom:0;
      svg{

        path{
          fill:#50718f;


        }
      }
    }
  }

  .box-body{
    font-size: 14px;

    tr{

      td{
        padding:7px;
      }
      td:first-child{
        color: #bfe0ff;
      }
    }
  }

  .table{
    margin: 0;
    border-color:#4b7092;

    tr{

      td{
        border-color:#4b7092;
      }
    }
  }


  &.left{


    left: auto;
    right: 60px;


    &::after{

      right: all;
      left: 100%;
      transform: rotate(180deg);
    }

  }
}

.row-gallery{

  .item{
    overflow: hidden;
    display: block; 
    margin-bottom: 15px;
    .img{
      background-size:no-repeat;
      height: 200px;
      background-size: cover;
      transition:all 0.3s;
      display: block;

      @include media(medium){
        height: 300px;
      }
    }


    &:hover .img{
      transform: scale(1.1);
    }
  }
}
#location-map{
  margin-bottom: 50px;
}

.bod{
  height: 400px;
  background:#abb0b4;
  text-align: left;
  box-shadow:4px 3px 9px #d9d9d9;
  margin-bottom: 30px;

  .photo{
    height: 60%;
    position:relative;
    overflow: hidden;
background: rgb(226,226,226);
background: -moz-radial-gradient(circle, rgba(226,226,226,1) 31%, rgba(171,176,180,1) 100%);
background: -webkit-radial-gradient(circle, rgba(226,226,226,1) 31%, rgba(171,176,180,1) 100%);
background: radial-gradient(circle, rgba(226,226,226,1) 31%, rgba(171,176,180,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2e2e2",endColorstr="#abb0b4",GradientType=1);

    
    img{
      left:50%;
      bottom:0;
      transform: translateX(-50%);
      position:absolute;
    }
  }
  .bio{
    background:#aaa575;
    height: 40%;
    position:relative;
    padding: 25px;
    color: #fff;


    .name{
      font-size: 25px;
      font-family: 'proza_libremedium';
      line-height: 25px;
      margin-bottom: 7px;
    }
    .position{
      font-size: 12px;
      text-transform: uppercase;
    }

    .button{
      position:absolute;
      bottom:20px;
      left: 25px;
      right: 25px;
      font-family: 'proza_libresemibold';

      button{
          
          font-size: 13px;
          text-transform: uppercase;
          background:0;
          border:0;
          outline: 0;
          padding:0;
          letter-spacing: 2px;
      }

      span{
        float: right;
        transition: all 0.3s;
      }



    }



  }


  &.green{ .bio{  background: #86a692;  }}
  &.blue{ .bio{  background: #7090ae;  }}
  &.orange{ .bio{  background: #ce946d;  }}



  &:hover{

    .button span{

    }
  }
}

.modal-dialog{
  max-width: 1208px;

  .modal-content{
    padding:20px;

    @include media(x-small){
      padding:50px;
    }

    @include media(medium){
      padding:90px;
    }
  }


  .modal-header{
    border-bottom:4px solid #bfbb93;
    padding-left:0;
    padding-right:0;
    padding-bottom:30px;
    position:relative;
    .close{
      outline: 0;
      position: relative;
      z-index: 80;
    }
  }

  .pop-photo{
    display: inline-block;
    background:#abb0b4;
    vertical-align: middle;
    width: 150px;
    position:relative;
    text-align: center;
    overflow: hidden;

    background: rgb(226,226,226);
background: -moz-radial-gradient(circle, rgba(226,226,226,1) 31%, rgba(171,176,180,1) 100%);
background: -webkit-radial-gradient(circle, rgba(226,226,226,1) 31%, rgba(171,176,180,1) 100%);
background: radial-gradient(circle, rgba(226,226,226,1) 31%, rgba(171,176,180,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2e2e2",endColorstr="#abb0b4",GradientType=1);

     @include media(x-small){
      width: 200px;
    }

    @include media(medium){
      width: 280px;
    }

    img{
      max-width: 100%;
    }
  }
  .pop-meta{
    display: inline-block;
    vertical-align: middle;
    padding-left:20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);


    @include media(x-small){
      padding-left:50px;
    }

    @include media(medium){
      padding-left: 70px;
    }

    h2{
      margin-bottom:10px;
      font-size: 20px;
      color: #3e3e3e;
      margin-top: 0;
      padding-top:0;


      @include media(x-small){
        font-size: 30px;
      }
    }
    h3{
      text-transform: uppercase;
      color: #6c6c6c;
      font-size: 14px;
      margin: 0;
      padding-top:0;
      font-family: 'proza_libreregular';


      @include media(x-small){
        font-size: 18px;
      }
    }
  }
  .modal-body{
    padding:30px 0 0;
    font-size: 14px;

    p{
      margin-bottom: 20px;


      @include media(max-small){
        font-size: 12px;
        margin-bottom: 15px;
      }
    }


    @include media(x-small){
      font-size: 16px;
    }


     
  }
  @include media(x-small){
    width: 80%;
  }
}


#disclaimer{

  pointer-events: none;
  .modal-dialog{
    pointer-events: none;


    .modal-content{
      pointer-events: all;
      overflow-y: auto;
      max-height: 96vh;
    }
  }
}
.modal{

  &.legal{




    .modal-dialog{  

      

      @include media(medium){

        width: 60%;

      }

      .modal-content{

      }

      .modal-header{
        border-bottom:0;
        padding: 0;
      }
      .modal-body{
        padding-top:0;
      }

      .modal-footer{
        padding-top:50px;
        text-align: center;
        padding-bottom:0;
        border-top:4px solid #bfbb93;


        @include media(max-small){
             padding-top: 15px;
        }

        .btn + .btn{
          margin-left:20px;
          margin-right: 20px;


          @include media(max-small){
            margin-left:10px;
            margin-right: 10px;
          }
        }

        .btn{

          @include media(max-small){
            padding: 6px 7px;
            font-size: 12px;
          }

        }
      }
    }
  }
}


.legend-county{
  text-align: center;
  left: 10px;
  position:absolute;
  bottom:20px;
  margin-right: 50px;
  text-align:left;
  width: 300px;
  font-size: 12px;
  column-count: 2;
  span{
    display: block;
    padding:2px 0;

    &::before{
      width: 8px;
      height: 8px;
      display: block;
      content: "";
      background:#bfbb93;
      display: inline-block;
      border-radius:50%;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  ul{
    list-style: none;
    padding:0;
    margin:0;
     
  }
}

.ads{
  position:absolute;
  max-width: 100%;


  @include media(max-small){
    width: 70px;
  }

  &.top{
    right: 0;
    top: 0;

  }
}

.static-gallery{
  font-size: 0;
  text-align: center;
  padding:0;
  margin:0 0 30px 0;


  li{
    width: calc(100% / 3);
    display: inline-block;
    height: 130px;
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;


    @include media(small){


       width: calc(100% / 6);

    }
  }

}

.brand{
  display: block;
  width: 250px;
  margin:0 auto 30px;

  svg{
    width: 100%;
  }
}

form.gsc-search-box,
table.gsc-search-box{
  margin-bottom: 0 !important;
}
.gsc-control-cse{
  padding:0 !important;
}
.gsc-search-button{
  padding:3px 10px !important;
      border-color: #bfbb93 !important;
    background-color: $primary-color !important;

  svg{
    width: 19px !important;
    height: 19px !important;
  }
}

.gsib_a{
  padding:0 !important;
}

input.gsc-input, .gsc-input-box, .gsc-input-box-hover, .gsc-input-box-focus{
  border:0 !important;
}
.gsc-input-box{
  padding:0 !important;
  background:#e5e5e5 !important;
  border-radius:15px;
}

input.gsc-input{
  background: 0 !important;
  padding:4px 10px 4px 15px !important;
}

.gsc-results-wrapper-overlay{
  text-align: left;
}